
import {defineComponent, ref} from 'vue'
import {IFilter} from "@/core/entity/IFilter";
import TaskService from "@/core/services/TaskService";
import EntityLoading from "@/components/base/loading/EntityLoading.vue";
import store from "@/store";
import TaskListTable from "@/views/task/TaskListTable.vue";

export default defineComponent({
  name: "TaskOverDue",
  components: {TaskListTable, EntityLoading},
  setup() {
    const loading = ref(false);
    const filter = ref<IFilter>({
      paginationPage: 1,
      paginationSize: 20,
      key: {search: '', ep: 'claim,caze,invoice', s: '', overdue: true, myTasks: true},
    })
    const data = ref<any>([]);
    const loadData = () => {
      loading.value = true;
      TaskService.findAll<any>(filter.value).then(res => {
        data.value = res.data
      }).finally(() => {
        loading.value = false;
      })
    }
    store.subscribe((mutation) => {
      if (mutation.type === 'TASK_COMPLETE') {
        data.value = data.value.filter(item => item.id !== mutation.payload)
      }
    });
    loadData()
    return {
      data,
      loading,
    }
  }
})
