
import {computed, defineComponent, onMounted, ref} from "vue";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import DashboardService from "@/core/services/DashboardService";
import {ReportChartEvent} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";
import HighChart from "@/components/base/chart/HighChart.vue";
import store from "@/store";
import DashboardTask from "@/views/task/DashboardTask.vue";
import DashboardReminder from "@/views/reminder/DashboardReminder.vue";

export default defineComponent({
  name: "Dashboard",
  components: {DashboardReminder, DashboardTask, HighChart},
  setup() {
    const overviewProps = ref<any>({})
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams("Dashboard", [])
    })

    const createdCaseChart = computed(() => store.state.DashboardModule.caseCreatedChart)
    const createdClaimChart = computed(() => store.state.DashboardModule.claimCreatedChart)
    const invoiceCreatedChart = computed(() => store.state.DashboardModule.invoiceCreatedChart)
    const paymentCreatedChart = computed(() => store.state.DashboardModule.paymentCreatedChart)

    const loadOverview = () => {
      DashboardService.overview().then(res => {
        overviewProps.value = res;
      })
    }
    loadOverview();
    return {
      overviewProps,
      date: new Date().getTime(),
      ...ReportChartEvent(Actions.LOAD_CHART_CREATED_CASE, ref(0)),
      ...ReportChartEvent(Actions.LOAD_CHART_CREATED_CLAIM, ref(0)),
      ...ReportChartEvent(Actions.LOAD_CHART_CREATED_INVOICE, ref(0)),
      ...ReportChartEvent(Actions.LOAD_CHART_CREATED_PAYMENT, ref(0)),
      createdCaseChart,
      createdClaimChart,
      invoiceCreatedChart,
      paymentCreatedChart,
    }
  },
  methods: {
    showError() {
      throw new Error('Trident test error');
    }
  }
})
