
import {defineComponent} from 'vue'
import {LoadPanel} from "@/core/composite/composite";
import ReminderName from "@/views/reminder/ReminderName.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";

export default defineComponent({
  name: "ReminderTable",
  components: {DateTimeFormat, ReminderName},
  props: {
    items: {required: true},
  },
  setup() {
    return {
      ...LoadPanel()
    }
  }

})
