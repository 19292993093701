
import {defineComponent} from 'vue'
import {LoadPanel} from "@/core/composite/composite";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import TaskName from "@/views/task/TaskName.vue";

export default defineComponent({
  name: "TaskListTable",
  components: { TaskName,  DateTimeFormat},
  props: {
    items: {required: true},
  },
  setup() {
    return {
      ...LoadPanel()
    }
  }
})
